import { styled } from 'styled-components'

export const Title = styled.div`
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black};
`
